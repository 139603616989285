<template>
  <v-dialog v-model="dialog" max-width="450" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cloudflare Zone</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="save">
            Salvar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container fluid>
        <v-form ref="form" v-model="valid" class="px-2 pt-2 pb-0" lazy-validation>
          <v-text-field v-model="emailCredentials.login" append-outer-icon="mdi-account-badge" :rules="fieldRules" label="Login" required />
          <v-text-field v-model="emailCredentials.password" append-outer-icon="mdi-form-textbox-password" :rules="fieldRules" label="Password" required />
          <v-text-field v-model="emailChannel.domain" append-outer-icon="mdi-domain" :rules="fieldRules" label="Domínio" required />
          <v-text-field v-model="emailChannel.sender_name" append-outer-icon="mdi-cog" :rules="fieldRules" label="Nome" required />
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATE_EMAIL_CONFIG_PARAMS } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: Boolean,
    accountId: String
  },
  data: () => ({
    valid: null,
    emailCredentials: {
      login: '',
      password: ''
    },
    emailChannel: {
      domain: '',
      sender_name: ''
    },
    fieldRules: [v => !!v || 'Campo obrigatório']
  }),
  methods: {
    async save() {
      if (!this.$refs.form.validate()) {
        this.cleanFields()
        return this.$snackbar({ message: 'Falha ao salvar dados, favor verificar os campos.' })
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_EMAIL_CONFIG_PARAMS,
          variables: {
            id: this.accountId,
            login: this.emailCredentials.login,
            password: this.emailCredentials.password,
            domain: this.emailChannel.domain,
            sender_name: this.emailChannel.sender_name
          }
        })
        this.$snackbar({ message: 'Configuração de email salva com sucesso' })
        this.$emit('close')
        this.cleanFields()
      } catch (err) {
        console.log('Failed to save email config', err)
        this.$snackbar({ message: 'Falha ao salvar configuração de email' })
      }
    },
    cleanFields() {
      this.emailCredentials.login = ''
      this.emailCredentials.password = ''
      this.emailChannel.domain = ''
      this.emailChannel.sender_name = ''
    }
  }
}
</script>
